var BASE_URL = $("#base_url").val();

$(".btn-repeat-form").on("click", function () {
    var button = $(this);
    var form = $("#" + button.data("form"));
    var id = form.find('input[name=logid]').val();
    var email = form.find('#email_prevendedor').val();

    button.addClass("saving").attr("disabled", true);
    $.ajax({
        type: "POST",
        url: BASE_URL + '/api/resend-lead',
        data: { id: id, prevendedor: email },
        success: function (response) {
            button.removeClass("saving").attr("disabled", false);
            var parents = button.parents("div.modal-footer");

            if (response.success) {
                parents.find(".btn-success-resend").removeClass('d-none')
                parents.find(".btn-repeat-form").addClass('d-none')
                parents.find(".alerts").html(alert('success', 'Sucesso', 'Lead cadastrado com sucesso!'));
            } else {
                parents.find(".alerts").html(alert('danger', 'Erro', response.error));
            }
        }
    });
});

function alert(type, title, message) {
    return "<div class=\"alert alert-" + type + " alert-dismissible fade show\" role=\"alert\">\
        <strong>" + title + "!</strong> " + message + "\
        <button type=\"button\" class=\"close\" data-dismiss=\"alert\" aria-label=\"Close\">\
        <span aria-hidden=\"true\">&times;</span>\
        </button>\
    </div>";
}